import { Alert, Badge, Box, Button, createStyles, Divider, Flex, Grid, Group, Input, Modal, Text, Tooltip } from "@mantine/core";
import { IconFileText } from "@tabler/icons";
import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { FundingIntroductionApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IFundingIntroduction } from "../../interfaces/IFundingIntroduction";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IAccount, IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { Form } from "../form";
import { SelectItemField } from "../form/select-item-field";
import { FundingIntroductionForm } from "../funding-introduction/form";
import { FundingIntroductionPin } from "../funding-introduction/pin";
import { ColumnType, ITableRef, Table } from "../table";

const schema = yup.object({});

type IFormData = {
  accountId: string;
};
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  number: string;
  bankName: string;
  item: any;
}

export const ScfFundingIntroductionSupplierList = () => {
  const { classes } = useStyle();
  const nowMoment = new Date();
  const ref = React.useRef<ITableRef>(null);
  const [result, setResult] = React.useState<any>();
  const [action, setAction] = React.useState<string[]>([]);
  const [accountId, setAccountId] = React.useState<any>();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { currencies, accounts, fundingInstructionTypes, fundingInstructionStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [accountItem, setAccountItem] = React.useState<any>();

  const [datas] = React.useState<IFormData>({
    accountId: "",
  });

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ number, bankName, item, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <div>
        <Text size="sm">{item.number}</Text>
        <Text size="xs" opacity={0.65}>
          {item.shortName}
        </Text>
      </div>
    </div>
  ));

  const { data: accountData } = useSwr(
    `/api/account?${accountId}`,
    async () => {
      if (!accountId) {
        return [];
      } else if ((user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_FUNDING_INSTRUCTION_MANAGEMENT_SL" && c.isView).length > 0) {
        let res = await FundingIntroductionApi.accountGet(accountItem);
        return res;
      }
      return [];
    },
    {},
  );

  const columns = useHeader({
    user,
    currencies,
    fundingInstructionTypes,
    fundingInstructionStatus,
    onClick: (key, record) => {
      switch (key) {
        case "view": {
          setAction(["view", record as any]);
          break;
        }
      }
    },
  });

  const collapseChildren = (record: any) => {
    return (
      <div className={classes.card}>
        <Grid columns={28}>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.refCode || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[0]?.createdAt)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              <Badge
                size="md"
                radius="sm"
                variant="outline"
                color={(fundingInstructionStatus || []).find((item: IReference) => item.code === record?.children[0]?.fundingInstructionStatus)?.color}>
                {(fundingInstructionStatus || []).find((item: IReference) => item.code === record?.children[0]?.fundingInstructionStatus)?.name}
              </Badge>
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[0]?.disburseDate)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[0]?.disburseConfirmDate)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[0]?.trxConfirmDate)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {tugrug(record?.children[0]?.trxAmount || "0")}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              <Badge size="md" radius="sm" variant="outline">
                {currencies.find((c: any) => c.code === record?.children[0]?.trxCurrency)?.name}
              </Badge>
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              <Badge size="md" radius="sm" variant="outline">
                {(fundingInstructionTypes || []).find((c) => c.code === record?.children[0]?.trxType)?.name}
              </Badge>
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.paymentMethod || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.partnerRegNum || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.bankCode || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.debtorAccName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.debtorAccNum || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.description || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.debtorBankName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.creditorAccName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.debtorAccNum || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.creditorBankName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.description || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {tugrug(record?.children[0]?.trxAmount || "0")}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.invRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.scfRequestRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.resolutionNo || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.partnerRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.partnerName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.businessRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[0]?.businessName || "-"}
            </Text>
          </Grid.Col>
        </Grid>
        <Grid columns={28}>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.refCode || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[1]?.createdAt)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              <Badge
                size="md"
                radius="sm"
                variant="outline"
                color={
                  (fundingInstructionStatus || []).find(
                    (item: IReference) => item.code === record?.children[1]?.fundingInstructionStatusrecord?.children[1]?.fundingInstructionStatus,
                  )?.color
                }>
                {
                  (fundingInstructionStatus || []).find(
                    (item: IReference) => item.code === record?.children[1]?.fundingInstructionStatusrecord?.children[1]?.fundingInstructionStatus,
                  )?.name
                }
              </Badge>
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[1]?.disburseDate)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[1]?.disburseConfirmDate)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {dateSecFormat(record?.children[1]?.trxConfirmDate)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {tugrug(record?.children[1]?.trxAmount || "0")}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              <Badge size="md" radius="sm" variant="outline">
                {currencies.find((c: any) => c.code === record?.children[1]?.trxCurrency)?.name}
              </Badge>
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              <Badge size="md" radius="sm" variant="outline">
                {(fundingInstructionTypes || []).find((c) => c.code === record?.children[0]?.trxType)?.name}
              </Badge>
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.paymentMethod || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.partnerRegNum || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.bankCode || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.debtorAccName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.debtorAccNum || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.description || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.debtorBankName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.creditorAccName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.debtorAccNum || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.creditorBankName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.description || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {tugrug(record?.children[1]?.trxAmount || "0")}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.invRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.scfRequestRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.resolutionNo || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.partnerRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.partnerName || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.businessRef || "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="xs" color="dimmed">
              {record?.children[1]?.businessName || "-"}
            </Text>
          </Grid.Col>
        </Grid>
      </div>
    );
  };

  const reload = async () => {
    ref.current?.reload();
    setAction([]);
  };

  return (
    <>
      <Form validationSchema={schema} onSubmit={() => {}} initialValues={datas}>
        {({ values }) => {
          return (
            <Flex sx={{ position: "relative" }} direction="column">
              <Grid>
                <Grid.Col span={4}>
                  <SelectItemField
                    name="accountId"
                    label="Олголт хийх данс сонгох:"
                    placeholder="Сонгох"
                    clearable
                    searchable
                    itemComponent={SelectItem}
                    onChange={(item) => {
                      setAccountItem(item);
                      setAccountId(item as any);
                    }}
                    options={accounts
                      .filter((i) => i.type === "DISBURSEMENT")
                      .map((item: IAccount) => {
                        return { value: item.id || "", label: `${item.name}, ${item.number}` || "", item };
                      })}
                    filter={(value: any, item: any) =>
                      item.label.toLowerCase().includes(value.toLowerCase().trim()) || item.value.toLowerCase().includes(value.toLowerCase().trim())
                    }
                  />
                </Grid.Col>
                {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_FUNDING_INSTRUCTION_MANAGEMENT_SL" && c.isView).length > 0 && (
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Олголт хийх дансны үлдэгдэл:">
                      <Box color="indigo" mt={4}>
                        <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="lg" fw={600}>
                          {tugrug(accountData?.balance) || "-"}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Одоогийн огноо, цаг:">
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="lg" fw={600} mt={4}>
                      {dateSecFormat(nowMoment)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Олгохоор хүлээж буй дүн:">
                    <Box color="indigo">
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(result?.disbursePendingAmount)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Батлах хүлээж буй дүн:">
                    <Box color="indigo">
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(result?.confirmPendingAmount)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Цэнэглэх ёстой дүн:">
                    <Box color="indigo">
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(result?.toDepositAmount)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Олгохоор хүлээж буй хүсэлт тоо:">
                    <Box color="indigo">
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {result?.disbursePendingCount || "0"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Батлах хүсэлтийн тоо:">
                    <Box color="indigo">
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {result?.confirmPendingCount || "0"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Үлдэгдэл хүрэлцэх байдал:">
                    {accountItem ? (
                      <Box>
                        {result?.isSufficient ? (
                          <Text color="green" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            Үлдэгдэл хүрэлцэнэ
                          </Text>
                        ) : (
                          <Text color="red" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            Үлдэгдэл хүрэлцэхгүй
                          </Text>
                        )}
                      </Box>
                    ) : (
                      <Text>-</Text>
                    )}
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Alert styles={{ message: { color: "#44566c", fontWeight: 500 } }} radius="md" color="indigo" withCloseButton={false} title="Таны анхааралд">
                    Та олголт хийх дансыг сонгон. Олголт хийх гүйлгээний мэдээлэл, олголтын SCF татах шимтгэл, олголт хийгдэхэд татаж авах бусад шимтгэлийн
                    мэдээллийг харах боломжтой.
                  </Alert>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Table
                    onResult={(setData: string) => setResult(setData)}
                    collapse
                    ref={ref}
                    name="scf.funding.intorduction.supplier.list"
                    columns={columns}
                    filters={{ accountId: accountId, productType: "SUPPLIER_LED" }}
                    collapseChildren={collapseChildren}
                    loadData={(data) => FundingIntroductionApi.list(data!)}
                  />
                </Grid.Col>
              </Grid>
            </Flex>
          );
        }}
      </Form>
      <Modal opened={action[0] === "view"} onClose={() => setAction([])} withCloseButton={false} size="80%" centered>
        <FundingIntroductionForm action={action} setAction={setAction} reload={reload} />
      </Modal>
      <Modal opened={action[0] === "pin"} onClose={() => setAction([])} withCloseButton={false} centered>
        <FundingIntroductionPin
          action={action}
          reload={reload}
          onCancel={() => setAction([])}
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
        />
      </Modal>
    </>
  );
};

type HeaderProps = {
  user: any;
  onClick: (key: string, record: IFundingIntroduction) => void;
  currencies: any;
  fundingInstructionTypes: IReference[];
  fundingInstructionStatus: IReference[];
};

const useHeader = ({ onClick, currencies, fundingInstructionStatus, fundingInstructionTypes, user }: HeaderProps): ColumnType<IFundingIntroduction>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "SCF" && c.code === "SCF_FUNDING_INSTRUCTION_MANAGEMENT_SL" && c.isView).length >
          0 ? (
            <Tooltip label="Олгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("view", record)}>
                <IconFileText />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Олгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconFileText />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 100 }}>
          {record.refCode || 0}
        </Text>
      );
    },
  },
  {
    title: "Огноо цаг",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },

  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(fundingInstructionStatus || []).find((item: IReference) => item.code === record.fundingInstructionStatus)?.color}>
          {(fundingInstructionStatus || []).find((item: IReference) => item.code === record.fundingInstructionStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Олголтын огноо, цаг",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.disburseDate)}
        </Text>
      );
    },
  },

  {
    title: "Олголт батлах огноо, цаг",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.disburseConfirmDate)}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний дүн",
    sorter: true,
    dataIndex: "trxAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.trxAmount)}</Group>
        </Text>
      );
    },
  },
  {
    title: "Илгээх валют",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record.trxCurrency)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Гүйлгээний төрөл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {(fundingInstructionTypes || []).find((c: any) => c.code === record.trxType)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.paymentMethod || "-"}
        </Text>
      );
    },
  },
  {
    title: "ТТД/Register",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.partnerRegNum || "-"}
        </Text>
      );
    },
  },
  {
    title: "Банк код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.bankCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Шилжүүлэх дансны нэр",
    sorter: true,
    dataIndex: "debtorAccName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.debtorAccName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Шилжүүлэх дансны дугаар",
    sorter: true,
    dataIndex: "debtorAccNum",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.debtorAccNum || "-"}
        </Text>
      );
    },
  },
  {
    title: "Шилжүүлэх гүйлгээний утга",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Шилжүүлэх банк",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.debtorBankName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах дансны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.creditorAccName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах дансны дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.creditorAccNum || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах банкны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.creditorBankName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах гүйлгээний утга",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах гүйлгээний дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.trxAmount || 0)}</Group>
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх №",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{record.invRef || "-"}</Group>
        </Text>
      );
    },
  },
  {
    title: "Санхүүжилт хүсэлт №",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.scfRequestRef || "-"}
        </Text>
      );
    },
  },
  {
    title: "Шийдвэр дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.resolutionNo || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.partnerRef || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.partnerName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бизнес код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.businessRef || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.businessName || "-"}
        </Text>
      );
    },
  },
];

const useStyle = createStyles((theme) => ({
  content: {
    display: "flex",
    gap: "8px",
  },
  icon: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[5],
  },

  name: {},

  card: {
    width: "100%",
  },
}));
